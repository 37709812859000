<template>
    <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <div class="aq-item" style="min-height: 100vh;">
            <div class="detail" v-if="askDetail">
                <div class="question-item">
                    <div class="row">
                        <span class="gender" v-text="askDetail.gender == 1 ? '男' : '女'"></span>
                        <span class="age" v-text="askDetail.age + '歲'"></span>
                        <span class="create-time" v-text="askDetail.createdtime"></span>
                    </div>
                    <div class="title-w">
                        <span class="category" v-text="'#' + askDetail.illnesscategorycode"></span>
                        <h2 class="title" v-text="askDetail.title"></h2>
                    </div>
					<img src="../../../assets/imgs/pc/img_fenx@2x.png" alt="" class="icon-share" @click="doShare">
                    <p class="content" v-text="askDetail.note"></p>
                    <div class="sub-question" v-if="isCreator && askDetail.subAskList && askDetail.subAskList.length">
                        <span class="hint">追問:</span>
                        <span class="sub-question-v" v-text="askDetail.subAskList[0].note"></span>
                    </div>
                    <div class="btn-sub-question" v-if="isCreator && (! askDetail.subAskList || ! askDetail.subAskList.length)" @click="addSubAsk">追問</div>
                </div>
				<div class="img-list">
					<viewer :options="{toolbar: false, navbar: false}" class="item" v-for="(imgData, index) in askDetail.images" :key="index">
						<img :src="imgData.image">
					</viewer>
				</div>
                <div class="answer-list" v-if="askDetail.answerList.length">
                    <div class="answer-item" v-for="(item, index) in askDetail.answerList" :key="index">
                        <div class="row">
                            <span :class="{'icon-doctor': true, 'man': item.sex == 1}"></span>
                            <span class="doctor-name" v-text="'醫師' + item.doctorname"></span>
                            <span class="answer-time" v-text="item.createdtime"></span>
                        </div>
                        <p class="answer-content" v-text="item.note"></p>
                    </div>
                </div>
                <div class="un-answer" v-if="!askDetail.answerList.length"></div>
            </div>
            <loading v-if="loading" />
        </div>
		<div class="modal-sub-ask" v-if="show">
			<div class="modal-content">
				<textarea cols="30" rows="3" class="content" v-model="subContent" placeholder="請輸入追問內容"></textarea>
				<div class="row">
					<div class="btn-cancel" @click="onCancel">取消</div>
					<div class="btn-confirm" @click="onConfirm"><div class="lds-ring" v-if="showAddSubLoading"><div></div><div></div><div></div><div></div></div>{{showAddSubLoading ? '正在提交...' : '确定'}}</div>
				</div>
			</div>
		</div>
		<my-share :data="shareData" v-if="share" :onResult="()=>share=false"/>
    </van-pull-refresh>
</template>

<script>
    import MyShare from '@/layouts/MyShare.vue'
    import Loading from '@/layouts/Loading.vue'
    import ask_detail from '@/views/mixins/ask_detail' 
	export default {
        mixins: [
            ask_detail
        ],
        components: {
			MyShare,
            Loading,
        },
		data(){
            return {
                share: false,
                shareData: null,
                show: false,
                isRefresh: false,
                loading: true,
                askDetail: null,
                subContent: '',
                showAddSubLoading: false,
                isCreator: false,
            }
        },
        mounted: function(){
			var askid = this.$route.params.id
			if(parseInt(askid) == askid) {
				this.askDetail = JSON.parse(sessionStorage.getItem('detail'))
				this.isCreator = this.askDetail.createdby == this.$store.getters.clientId
				this.loading = false
			} else {
				this.$router.push('/')
			}
        },
        methods: {
            onRefresh: function() {
                this.loadData({askid: this.$route.params.id}, (data, error)=>{
					this.loading = false
                    this.isRefresh = false
                    if(data) {
						this.askDetail = data
                        this.isCreator = data.createdby == this.$store.getters.clientId
                    } else {
						this._toast.warning(this, error || '加載失敗!')
						setTimeout(() => {
							this.$router.push('/')
						}, 1500)
					}
				})
            },
            doShare: function(){
                this.shareData = {
                    title: this.askDetail.title,
                    url: `${location.origin}/ask/detail/${this.askDetail.askid}`,
                    baseUrl: location.origin
                }
                this.share = true
            },
            addSubAsk: function(){
                this.show = true
            },
			onCancel: function(){
				this.show = false
				document.body.setAttribute('style', this.style)
			},
			async onConfirm(){
				//添加追问
				if(this.showAddSubLoading) return
				if(!this.subContent.trim()) {
					this._toast.warning(this, '請輸入追問內容')
					return
				}
				this.showAddSubLoading = true
				try {
					var rs = await this.$store.dispatch('addQuestion', {
						ip: '',
						title: this.askDetail.title,
						askid: this.askDetail.askid,
						createdby: this.$store.getters.clientId,
						note: this.subContent.trim(),
					})
					this._toast.success(this, '添加追問成功')
					this.showAddSubLoading = false
					this.show = false
					document.body.setAttribute('style', this.style)
                    this.askDetail.subAskList = [{'note': this.subContent.trim()}]
				} catch (error) {
					this._toast.warning(this, error || '數據加載失敗...')
					this.showAddSubLoading = false
				}
			}
        },
        watch: {
            '$route.params.id': function(askid) {
				if(parseInt(askid) == askid) {
					this.askDetail = JSON.parse(sessionStorage.getItem('detail'))
					this.isCreator = this.askDetail.createdby == this.$store.getters.clientId
					this.loading = false
				} else {
					this.$router.push('/')
				}
            }
        }
	}
</script>

<style lang="scss" scoped>
	.aq-item {
		display: flex;
		flex-direction: column;
		margin: 16px 24px;
		// padding: 31px 24px 40px 24px;
	}

    .aq-item .question-item {
        background-color: #fff;
        padding: 39px 24px 41px 24px;
    }

	.aq-item .row {
		display: flex;
		flex-direction: row;
	}

	.aq-item .title-w {
		margin-top: 44px;
		margin-bottom: 28px;
	}

	.aq-item .title-w .category {
		display: inline-block;
		color: #FF8000;
		line-height: 48px;
		font-size: 40px;
		font-weight: bold;
	}

	.aq-item .title-w .title {
		display: inline;
		color: #231F20;
		line-height: 48px;
		font-size: 40px;
		font-weight: bold;
        margin-left: 8px;
	}
	
	.aq-item .icon-share {
		position: absolute;
		right: 40px;
		top: 26px;
	}

	.aq-item .gender,
	.aq-item .age,
	.aq-item .create-time {
		color: #969696;
		font-size: 24px;
		line-height: 23px;
		font-weight: 400;
	}

	.aq-item .gender {
		margin-right: 8px;
	}

	.aq-item .age {
		margin-right: 28px;
	}

	.aq-item .content {
		line-height: 36px;
		color: #636363;
		font-weight: 400;
		font-size: 28px;
	}

    .aq-item .sub-question {
        margin-top: 39px;
    }

    .aq-item .sub-question > .hint {
        font-size: 32px;
        line-height: 32px;
        color: #36C4D0;
        font-weight: bold;
    }

    .aq-item .sub-question > .sub-question-v {
        font-size: 32px;
        line-height: 32px;
        color: #231F20;
        font-weight: bold;
        margin-left: 8px;
    }

	.aq-item .answer-list {
		background-color: #fff;
		padding-bottom: 30px;
        margin-top: 16px;
        padding-top: 8px;
	}

	.aq-item .answer-list > .answer-item {
		display: flex;
		flex-direction: column;
		padding: 40px 24px 0px 24px;
	}

	.aq-item .answer-list > .answer-item .icon-doctor {
		width: 32px;
		height: 32px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/mobile/img_nvyis@2x.png);
	}
 
    .aq-item .answer-list > .answer-item .icon-doctor.man {
        background-image: url(../../../assets/imgs/mobile/img_nanysy@2x.png);
    }

	.aq-item .answer-list > .answer-item .doctor-name {
		margin-left: 15px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
        line-height: 32px;
	}

	.aq-item .answer-list > .answer-item .answer-time {
		margin-left: 19px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
        line-height: 32px;
	}

	.aq-item .answer-list > .answer-item .answer-content {
		margin-left: 47px;
		color: #231F20;
		font-size: 32px;
        line-height: 48px;
		font-weight: 400;
		margin-top: 20px;
	}

	.aq-item .btn-sub-question {
        border-radius: 8px;
        width: 118px;
        height: 48px;
        line-height: 48px;
        background-color: #36C4D0;
        color: #fff;
        font-size: 32px;
        font-weight: 400;
        text-align: center;
        margin-top: 39px;
	}

    .aq-item .btn-sub-question:active {
        opacity: 0.7;
    }

	.aq-item .btn-sub-question > .icon-sub-question {
		width: 24px;
		height: 22px;
		margin-right: 16px;
		vertical-align: middle;
	}

	.aq-item .btn-sub-question > .hint {
		color: #231F20;
		font-size: 26px;
		font-weight: 400;
	}

	.aq-item .btn-sub-question.disable > .hint {
		color: #BFBFBF;
	}

    .un-answer {
        position: relative;
        margin-top: 30px;
    }

    .un-answer::after {
        content: '\9084\6C92\6709\91AB\5E2B\56DE\7B54\8A72\554F\984C';
        color: #b7b7b7;
        font-size: 34px;
        font-weight: 400;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
    }


	.modal-sub-ask {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		background-color: rgba(0, 0, 0, .2);
	}

	.modal-content {
		width: 72%;
		height: 300px;
		padding: 24px;
		box-sizing: border-box;
		border-radius: 24px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
	}

	.modal-content > .row {
		height: 64px;
		margin-top: 20px;
		width: 100%;
		display: flex;
		justify-content: space-evenly;
	}
	
	.modal-content > .row > .btn-confirm,
	.modal-content > .row > .btn-cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		border-radius: 12px;
		border: 1px solid #e7e7e7;
		background-color: #fff;
		font-size: 28px;
		font-weight: bold;
		color: #999;
		overflow: hidden;
	}

	.modal-content > .row > .btn-confirm {
		margin-left: 24px;
		color: #666;
	}

	.modal-content > .row > .btn-confirm:active,
	.modal-content > .row > .btn-cancel:active {
		opacity: 0.7;
	}

	.modal-content > textarea {
		width: 100%;
		flex: 1;
		background-color: #f0f0f0;
		padding: 12px;
		box-sizing: border-box;
		font-size: 32px;
		font-weight: bold;
		color: #231F20;
	}

	.modal-content > textarea::placeholder {
		font-weight: 400;
		font-size: 28px;
		color: #999;
	}

	/** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 8px;
        border: 8px solid #eee;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #eee transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    /** loading end */ 

    .img-list {
        background-color: #fff;
        padding: 0 20px 10px 20px;
    }

    .img-list > .item {
        display: inline-block;
        height: 200px;
        width: 200px;
        margin: 0px 8px 8px 0px;
    }

    .img-list img {
        display: inline-block;
        height: 200px;
        width: 200px;
        object-fit: fill;
    }
</style>
